import { useState } from "react";
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  List,
  ListItem,
  Paper,
  TextField,
  Typography,
  Radio
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { makeStyles } from "tss-react/mui";
import ColourConstants from "helpers/colourConstants";
import { ReactComponent as DeleteIcon } from "assets/icons/deleteIcon.svg";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

const useStyles = makeStyles()((theme) => ({
  deleteIcon: {
    transform: "scale(0.6)",
    color: ColourConstants.deleteButton,
    opacity: 0.7,
    "&:hover": {
      cursor: "pointer",
    },
  },
  error: {
    color: "#f44336",
    fontSize: "0.75rem",
    margin: 0,
    marginLeft: "10px",
  },
}));

/**
 * A fully controlled child component:
 * - Renders the schedule from props
 * - Calls onScheduleChange with updated data on user actions
 * - Does NOT store schedule in local state (only ephemeral things like `newTime`)
 */
const TimeScheduleEdit = ({ schedule, onScheduleChange }) => {
  const { classes } = useStyles();

  // Only ephemeral local state for "newTime" input; 
  // everything else is controlled by the parent.
  const [newTime, setNewTime] = useState("");

  // Derive the schedule type from the parent data
  const isPeriodic = schedule?.SyncPeriodHours !== null;

  // Handle switching to periodic
  const handleSwitchToPeriodic = () => {
    onScheduleChange({
      ...schedule,
      // fallback to a default if not set
      SyncPeriodHours: schedule.SyncPeriodHours || 12,
      // typically no times if periodic
      SyncTimeOfDay: []
    });
  };

  // Handle switching to daily
  const handleSwitchToDaily = () => {
    onScheduleChange({
      ...schedule,
      // no periodic hours
      SyncPeriodHours: null,
      // keep existing times or default to []
      SyncTimeOfDay: schedule.SyncTimeOfDay || []
    });
  };

  // Handle changes to the periodic hours
  const handlePeriodicHoursChange = (e) => {
    const value = e.target.value;
    // Basic validation
    if (value === "" || (value >= 1 && value <= 24)) {
      onScheduleChange({
        ...schedule,
        SyncPeriodHours: value ? parseInt(value, 10) : null
      });
    }
  };

  // Helper function to format time with seconds
  const formatTimeWithSeconds = (time) => {
    if (!time) return "";
    return `${time}:00`;
  };

  // Handle adding a new time
  const handleAddTime = () => {
    if (!newTime) return;
    if (!Array.isArray(schedule.SyncTimeOfDay)) {
      schedule.SyncTimeOfDay = [];
    }
    const timeWithSeconds = formatTimeWithSeconds(newTime);
    // Only add if it doesn't exist already
    if (!schedule.SyncTimeOfDay.includes(timeWithSeconds)) {
      const updated = [...schedule.SyncTimeOfDay, timeWithSeconds].sort();
      onScheduleChange({
        ...schedule,
        SyncPeriodHours: null,
        SyncTimeOfDay: updated
      });
      setNewTime("");
    }
  };

  // Handle deleting a time
  const handleDeleteTime = (time) => {
    const updated = schedule.SyncTimeOfDay?.filter((t) => t !== time) || [];
    onScheduleChange({
      ...schedule,
      SyncTimeOfDay: updated
    });
  };

  // Handle editing an existing time
  const handleEditTime = (index, value) => {
    const newTimes = [...(schedule.SyncTimeOfDay || [])];
    newTimes[index] = formatTimeWithSeconds(value);
    onScheduleChange({
      ...schedule,
      SyncTimeOfDay: newTimes
    });
  };

  const removeSeconds = (time) => time?.replace(/:00$/, '') || '';

  const checboxWrapperStyle = {
    bgcolor: "#f2f5f7",
    p: 2,
    mb: 1,
    display: "flex",
    alignItems: "start",
    borderRadius: 1.5,
    justifyContent: "start",
    flexDirection: "column",
    cursor: "pointer",
    userSelect: "none",
    padding: "20px 25px",
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      {/* PERIODIC */}
      <Paper elevation={0} sx={checboxWrapperStyle}>
        <FormControlLabel
          control={
            <Radio
              checked={isPeriodic}
              onChange={handleSwitchToPeriodic}
            />
          }
          label={
            <Typography style={{ fontSize: "14px", fontWeight: "bold" }}>
              Periodic Sync
            </Typography>
          }
        />
        {isPeriodic && (
          <span
            style={{
              display: "flex",
              gap: 5,
              paddingLeft: "32px",
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            <span
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: 5,
              }}
            >
              Every
              <TextField
                size="small"
                type="number"
                placeholder="Enter hours"
                value={
                  schedule?.SyncPeriodHours
                    ? schedule.SyncPeriodHours.toString()
                    : ""
                }
                onChange={handlePeriodicHoursChange}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: 5,
                  backgroundColor: "white",
                  width: "85px",
                }}
              />
              hours
            </span>
          </span>
        )}
      </Paper>

      {/* DAILY */}
      <Paper
        elevation={0}
        sx={checboxWrapperStyle}
        style={{ marginTop: "10px" }}
      >
        <FormControlLabel
          control={
            <Radio
              checked={!isPeriodic}
              onChange={handleSwitchToDaily}
            />
          }
          label={
            <Typography style={{ fontSize: "14px", fontWeight: "bold" }}>
              Daily Times
            </Typography>
          }
        />

        {!isPeriodic && (
          <List sx={{ paddingLeft: "32px" }}>
            {(schedule.SyncTimeOfDay || []).map((time, index) => (
              <ListItem
                key={index}
                disableGutters
                sx={{ marginBottom: "10px", gap: 1 }}
              >
                <TextField
                  size="small"
                  type="time"
                  value={removeSeconds(time)}
                  onChange={(e) => handleEditTime(index, e.target.value)}
                  sx={{
                    width: "120px",
                    backgroundColor: "white",
                  }}
                />
                <AccessTimeIcon sx={{ color: "black", fontSize: 20 }} />
                <DeleteIcon
                  className={classes.deleteIcon}
                  onClick={() => handleDeleteTime(time)}
                />
              </ListItem>
            ))}
            
            {schedule.SyncTimeOfDay?.length > 0 && (
              <Divider sx={{ my: 2 }} />
            )}
            
            <ListItem disableGutters>
              <TextField
                size="small"
                type="time"
                placeholder="Enter time"
                value={newTime}
                onChange={(e) => setNewTime(e.target.value)}
                sx={{ flex: 1, marginRight: 1 }}
              />
              <Button
                startIcon={<AddIcon />}
                onClick={handleAddTime}
                color="success"
              >
                Add Time
              </Button>
            </ListItem>
          </List>
        )}
      </Paper>
    </Box>
  );
};

export default TimeScheduleEdit;
