import { CircularProgress, Grid } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import RestoreIcon from "@mui/icons-material/Restore";

import { appPath, defectsPath } from "helpers/routePaths";
import { getLocalStorageData } from "helpers/utils";
import { changeDateForSapError } from "helpers/date";
import { HistoryCaptions, NoReadOnly } from "helpers/constants";
import ColourConstants from "helpers/colourConstants";
import { READONLY_ACCESS } from "constants/AccessTypes/AccessTypes";

import {
  getDefectDetail,
  raiseNotification,
} from "services/defects/details";
import { getIntegrationDefinitions, getIntegrationInstances } from "services/integrations";
import { defectsPage } from "services/History/models";

import {
  setHistoryDrawerState,
  showError,
} from "../../../redux/common/actions";

import ActionButtonStyle from "styles/application/ActionButtonStyle";
import { makeStyles } from "tss-react/mui";

import NavDetails from "components/Elements/NavDetails";
import TabTitle from "components/Elements/TabTitle";
import AccessWrapper from "components/Modules/AccessWrapper";
import HistoryBar from "components/Modules/HistorySidebar/HistoryBar";

import Notes from "./Notes";
import DefectImages from "./Images";
import ChangeStatusPopup from "./ChangeStatusPopup";
import Details from "./details";
import Audio from "./Audio";
import Parts from "./Parts";
import IntegrationName from "./IntegrationName";

const AT = ActionButtonStyle();
const media = "@media (max-width: 414px)";

/**
 * Styles
 */
const useStyles = makeStyles()((theme) => ({
  restore: {
    border: "2px solid",
    borderRadius: "100%",
    height: "35px",
    width: "35px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#307ad6",
  },
  buttons: {
    display: "flex",
    marginLeft: "auto",
    [media]: {
      marginLeft: "0px",
      flexDirection: "column",
      marginBottom: "10px",
      gap: "10px",
    },
  },
  raiseDefectButton: {
    display: "flex",
    marginLeft: "auto",
    backgroundColor: "yellow",
    [media]: {
      marginLeft: "0px",
      flexDirection: "column",
      marginBottom: "10px",
      gap: "10px",
    },
  },
  loaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(212, 212, 212, 0.5)",
    position: "absolute",
    width: "100%",
    height: "100%",
    left: "0",
    top: "0",
    borderRadius: "4px",
  },
  wrapper: {
    display: "flex",
    [media]: {
      marginTop: "10px",
      justifyContent: "space-between",
      flexDirection: "column",
    },
  },
}));

/**
 * SX (styled) objects for inline usage with MUI `sx` prop
 */
const changeStatusButtonSx = {
  "&.MuiButton-root": {
    backgroundColor: "#ED8738",
  },
};

const raiseNotificationButtonSx = {
  textTransform: "uppercase",
  "&.MuiButton-root": {
    backgroundColor: "#23BB79",
    color: "white",
  },
  "&.Mui-disabled": {
    backgroundColor: "#d5d5d5",
    color: "grey",
  },
};

function DefectsDetails() {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { isHistoryDrawerOpen } = useSelector((state) => state.commonData);
  const { id } = useParams();

  const { customCaptions, siteAppID, application, position } =
    getLocalStorageData("me");

  const [isChangeStatusOpen, setIsChangeStatusOpen] = useState(false);
  const [integrationInstance, setIntegrationInstance] = useState(null);
  const [integrationDefinitions, setIntegrationDefinitions] = useState(null);
  const [defectDetails, setDefectDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isRaiseDefectLoading, setIsRaiseDefectLoading] = useState(false);

  const readOnly = position?.defectAccess === READONLY_ACCESS;

  const handleToggleChangeStatus = () =>
    setIsChangeStatusOpen((prev) => !prev);

  /**
   * Fetch defect details and integration instance
   */
  const handleFetchDefect = useCallback(async () => {
    try {
      const [defectResponse, integrationResponse, definitionsResponse] = await Promise.all([
        getDefectDetail(id),
        getIntegrationInstances(),
        getIntegrationDefinitions(),
      ]);

      if (defectResponse.status) {
        setDefectDetails(defectResponse.data);
      }

      if (definitionsResponse.status) {
        setIntegrationDefinitions(definitionsResponse.data);
      }

      // Handle integration instances - filter active ones only
      if (integrationResponse?.status) {
        const activeInstances = integrationResponse.data?.filter(
          instance => instance.isActive
        ) || [];

        // Only set instance if we have active ones
        if (activeInstances.length > 0) {
          const rawInstance = activeInstances[0];
          const formattedInstance = {
            ...rawInstance,
            fieldMappings: rawInstance?.fieldMappings
              ? JSON.parse(rawInstance?.fieldMappings) ?? {}
              : {},
            settings: rawInstance?.settings
              ? JSON.parse(rawInstance?.settings) ?? {}
              : {}
          };
          setIntegrationInstance(formattedInstance);
        } else {
          setIntegrationInstance(null);
        }
      }
    } catch (error) {
      console.error("Error fetching defect details:", error);
      setIntegrationInstance(null);
    } finally {
      setIsLoading(false);
    }
  }, [id]);

  useEffect(() => {
    handleFetchDefect();
  }, [handleFetchDefect]);

  const defectStatus = {
    exported: defectDetails?.exported,
    sapError: defectDetails?.sapError,
    sapExportDateTime: defectDetails?.sapExportDateTime,
  };

  const isSynchronized =
    !defectDetails?.sapError && defectDetails?.sapExportDateTime;
  const hasError = !!defectDetails?.sapError;

  const canRaiseNotification = (instance) => {
    if (!instance?.id || !integrationDefinitions) return false;
    
    // Find the definition matching the instance's type
    const definition = integrationDefinitions.find(
      def => def.integrationType === instance.integrationType
    );
    return definition?.isDefectIntegration;
  };

  const handleRaiseDefect = async () => {
    setIsRaiseDefectLoading(true);
    try {
      const response = await raiseNotification(id);

      if (!response.status) {
        // Handle known error (e.g., duplicate notification)
        setDefectDetails((prev) => ({
          ...prev,
          sapError: response.data,
          sapExportDateTime: null,
        }));
        dispatch(showError(response.data));
      } else {
        // Success: refetch the latest data from the server
        await handleFetchDefect();
      }
    } catch (error) {
      // Generic server error
      await handleFetchDefect();
      dispatch(showError(error?.data ?? "Failed to raise notification."));
    } finally {
      setIsRaiseDefectLoading(false);
    }
  };

  if (isLoading) return <CircularProgress />;

  return (
    <div className="container">
      <TabTitle
        title={`${customCaptions.defect} ${defectDetails?.number} | ${application.name}`}
      />
      <ChangeStatusPopup
        open={isChangeStatusOpen}
        onClose={handleToggleChangeStatus}
        setDetails={setDefectDetails}
      />

      <HistoryBar
        id={id}
        showhistorybar={isHistoryDrawerOpen}
        dispatch={dispatch}
        fetchdata={(id, pageNumber, pageSize) =>
          defectsPage(id, pageNumber, pageSize)
        }
        origin={HistoryCaptions.defects}
      />

      <div className="topContainerCustomCaptions">
        <NavDetails
          status
          lastSaved={null}
          staticCrumbs={[
            {
              id: 1,
              name: customCaptions?.defectPlural || "Defects",
              url: `${appPath}${defectsPath}`,
            },
            {
              id: 2,
              name: defectDetails.number,
            },
          ]}
          hideLastLogin
          hideVersion
          showCreatedByAt
          showSDefects
          state={{
            modelStatusName: defectDetails?.defectStatusName,
            statusColor:
              defectDetails.defectStatusType === "C"
                ? ColourConstants.green
                : ColourConstants.red,
          }}
          defectDetails={{ defectDetail: defectStatus, defectIntegrationInstance: integrationInstance }}
          time={defectDetails.createdDateTime}
          userName={defectDetails.createdUserName}
        />

        <div className={classes.wrapper}>
          {/* Raise Notification Button */}
          <AccessWrapper access={position?.defectAccess} accessList={NoReadOnly}>
            <div className={classes.buttons}>
              {canRaiseNotification(integrationInstance) && (
                <AT.GeneralButton
                  sx={raiseNotificationButtonSx}
                  onClick={handleRaiseDefect}
                  className={classes.raiseDefectButton}
                  disabled={isSynchronized || isRaiseDefectLoading}
                >
                  Raise Notification
                  {isRaiseDefectLoading && (
                    <span className={classes.loaderContainer}>
                      <CircularProgress
                        sx={{ position: "absolute" }}
                        style={{ height: "25px", width: "25px" }}
                      />
                    </span>
                  )}
                </AT.GeneralButton>
              )}
            </div>
          </AccessWrapper>

          {/* Change Status Button */}
          <AccessWrapper access={position?.defectAccess} accessList={NoReadOnly}>
            <div className={classes.buttons}>
              <AT.GeneralButton
                sx={changeStatusButtonSx}
                onClick={handleToggleChangeStatus}
              >
                Change Status
              </AT.GeneralButton>
            </div>
          </AccessWrapper>

          {/* History Drawer Icon */}
          <div
            className="restore"
            onClick={() => dispatch(setHistoryDrawerState(true))}
          >
            <RestoreIcon className={classes.restore} />
          </div>
        </div>
      </div>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Details
            details={defectDetails}
            siteAppID={siteAppID}
            captions={customCaptions}
            defectId={id}
            fetchDefect={handleFetchDefect}
            isReadOnly={readOnly}
            defectDetail={defectStatus}
          />
        </Grid>

        <Grid item xs={12} lg={6}>
          <Grid item xs={12}>
            <DefectImages
              defectId={id}
              captions={customCaptions}
              isReadOnly={readOnly}
            />
          </Grid>

          <Grid item xs={12} style={{ marginTop: "16px" }}>
            <Notes defectId={id} isReadOnly={readOnly} />
          </Grid>
        </Grid>

        <Grid item xs={12} lg={6}>
          {defectDetails?.audioURL && (
            <Grid item xs={12} style={{ marginBottom: "16px" }}>
              <Audio
                src={defectDetails?.audioURL}
                defectId={id}
                isReadOnly={readOnly}
                setDetails={setDefectDetails}
              />
            </Grid>
          )}

          {application?.showDefectParts && (
            <Grid item xs={12}>
              <Parts
                captions={customCaptions}
                defectId={id}
                isReadOnly={readOnly}
              />
            </Grid>
          )}

          {hasError && (
            <Grid item xs={12} id="integration-name-status">
              <IntegrationName
                integrationName={`${integrationInstance?.name}`}
                lastAttempt={changeDateForSapError(
                  integrationInstance?.lastSyncAttempt
                )}
                sapError={defectStatus?.sapError}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default DefectsDetails;
