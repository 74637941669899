import { Box, Typography, Paper } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { makeStyles } from "tss-react/mui";
import ColourConstants from "helpers/colourConstants";
import EditScheduleLink from "../../EditIntegrationScheduleDialog/EditScheduleLink";
import { convertToAmPm } from "helpers/date";

const useStyles = makeStyles()((theme) => ({
	scheduledTime: {
		borderTop: `1.5px solid  ${ColourConstants.integrationInstanceBorder}`,
		padding: "12px 0",
	}
}));

const ScheduleSettings = ({ settings, isEditing, onEditSchedule, customCaptions }) => {
	const { classes } = useStyles();

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				gap: 12,
				borderTop: `1.5px solid ${ColourConstants.integrationInstanceBorder}`,
				padding: "10px 0",
			}}
		>
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					mb: 2,
				}}
			>
				<span>Sync Schedule</span>

				<EditScheduleLink
					isEditing={isEditing}
					onClick={onEditSchedule}
				/>
			</Box>

			<Paper
				elevation={0}
				sx={{
					bgcolor: "#f2f5f7",
					p: 2,
					mb: 2,
					display: "flex",
					alignItems: "center",
				}}
			>
				<AccessTimeIcon sx={{ color: "text.secondary", mr: 1 }} />
				<Typography
					variant="body1"
					color="text.secondary"
					sx={{ font: "bold" }}
				>
					{settings?.SyncPeriodHours ? (
						`Sync every ${settings.SyncPeriodHours} ${settings.SyncPeriodHours > 1 ? "hours" : "hour"}`
					) : Array.isArray(settings?.SyncTimeOfDay) && settings.SyncTimeOfDay.length > 0 ? (
						`Sync daily at ${settings.SyncTimeOfDay.map(time => convertToAmPm(time)).join(", ")}`
					) : (
						"No schedule set"
					)}
				</Typography>
			</Paper>
		</div>
	);
};

export default ScheduleSettings;
