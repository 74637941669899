import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, LinearProgress, Box, Typography, Icon } from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { makeStyles } from "tss-react/mui";
import { useDispatch } from "react-redux";
import { showError } from "../../../../../../redux/common/actions";
import AddDialogStyle from "styles/application/AddDialogStyle";
import ColourConstants from "helpers/colourConstants";
import IntegrationCard from "./IntegrationCard";
import { addIntegrationInstance } from "services/integrations";
import { safeParse } from "helpers/utils";

const ADD = AddDialogStyle();

const useStyles = makeStyles()((theme) => ({
	dialogContent: {
		display: "flex",
		flexDirection: "column",
		gap: 8,
	}
}));

const AddNewIntegrationModal = ({ open, onModalClose, existingIntegrations, definitions }) => {
	const dispatch = useDispatch();
	const { classes } = useStyles();
	const [isUpdating, setIsUpdating] = useState(false);

	const handleCreateIntegration = async (payload) => {
		setIsUpdating(true);
		try {
			// Find the definition for this integration type
			const definition = definitions.find(
				def => def.integrationType === payload.integrationType
			);

			if (!definition) {
				throw new Error("Integration definition not found");
			}

			// Parse the template strings from the definition
			const fieldMappings = safeParse(definition.defaultFieldMappingsTemplate, {});
			const settings = safeParse(definition.defaultSettingsTemplate, {});

			const response = await addIntegrationInstance({
				...payload,
				fieldMappings: JSON.stringify(fieldMappings),
				settings: JSON.stringify(settings)
			});

			if (response.status) {
				onModalClose(true);
			} else {
				dispatch(showError("Failed to create integration"));
				onModalClose(false);
			}
		} catch (error) {
			console.error("Error creating integration:", error);
			dispatch(showError("Failed to create integration"));
			onModalClose(false);
		} finally {
			setIsUpdating(false);
		}
	};

	return (
		<Dialog
			open={open}
			onClose={() => onModalClose(false)}
			aria-labelledby="add-integration-dialog"
		>
			{isUpdating && <LinearProgress />}
			
			<ADD.ActionContainer>
				<ADD.ButtonContainer>
					<ADD.CancelButton
						onClick={() => onModalClose(false)}
						variant="contained"
						sx={{
							"&.MuiButton-root:hover": {
								backgroundColor: ColourConstants.deleteDialogHover,
								color: "#ffffff",
							},
						}}
					>
						Cancel
					</ADD.CancelButton>
				</ADD.ButtonContainer>
			</ADD.ActionContainer>

			<DialogContent className={classes.dialogContent}>
				{definitions?.map((definition) => {
					const isAlreadyAdded = definition.isUnique && existingIntegrations.some(
						(integration) => integration.integrationType === definition.integrationType
					);
					console.log(`isAlreadyAdded: ${isAlreadyAdded} for ${definition.integrationType}`);
					return (
					<IntegrationCard
						key={definition.id}
						description={definition.description}
						name={definition.name}
						integrationType={definition.integrationType}
						integrationId={definition.id}
						logoUrl={definition.logoUrl}
						isAdding={isUpdating}
						handleClose={() => onModalClose(false)}
						handleCreateProcess={() => handleCreateIntegration({
							name: definition.name,
							description: definition.description,
							integrationType: definition.integrationType,
						})}
						isDisabled={isAlreadyAdded}
					/>
				);
			})}
				<Box
					sx={{
						mt: 2,
						p: 2,
						bgcolor: "#e0e0e0",
						borderRadius: 1,
						textAlign: "center",
					}}
				>
					<InfoOutlinedIcon sx={{ color: "text.secondary", mr: 1, verticalAlign: "middle" }} />
					<Typography variant="body2" color="text.secondary" component="span">
						If you would like a particular integration, please contact{" "}
						<a href="https://www.equipmentmanagement.com.au/contact" target="_blank" rel="noopener noreferrer">
							EMI support
						</a>.
					</Typography>
				</Box>
			</DialogContent>
		</Dialog>
	);
};

export default AddNewIntegrationModal;
