import React, { useState } from "react";
import { useParams } from "react-router-dom";
import IntegrationContent from "./IntegrationContent";

const Integrations = ({ state, dispatch }) => {
	const { appId } = useParams();
	const [is404, setIs404] = useState(false);

	if (is404 === false) {
		return (
			<>
				<IntegrationContent
					id={appId}
					setIs404={setIs404}
					state={state}
					dispatch={dispatch}
				/>
			</>
		);
	} else {
		return <p>404: Application id {appId} does not exist.</p>;
	}
};

export default Integrations;
