import {
	Dialog,
	DialogContent,
	DialogTitle,
	LinearProgress,
	Box,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import AddDialogStyle from "styles/application/AddDialogStyle";
import ColourConstants from "helpers/colourConstants";
import { showError } from "../../../../../../redux/common/actions";
import TextField from "@mui/material/TextField";
import Dropdown from "components/Elements/Dropdown";
import { getDefectRiskRatings } from "services/clients/sites/siteApplications/defectRiskRatings";

const ADD = AddDialogStyle();

const useStyles = makeStyles()((theme) => ({
	dialogContent: {
		display: "flex",
		flexDirection: "column",
		gap: 16,
	},
	createButton: {
		width: "auto",
	},
}));

const AddRiskRatingMappingsDialog = ({
	open,
	handleClose,
	instanceContent,
	customCaptions,
}) => {
	const dispatch = useDispatch();
	const { classes } = useStyles();
	const [isUpdating, setIsUpdating] = useState(false);
	const [input, setInput] = useState({
		defectRiskRating: null,
		code: "",
	});
	const [riskRatingOptions, setRiskRatingOptions] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const fetchRiskRatings = async () => {
			try {
				const response = await getDefectRiskRatings();
				if (response.status) {
					const mappedRatings = response.data.map((rating) => ({
						label: rating.name,
						value: rating.id,
						id: rating.id,
					}));
					setRiskRatingOptions(mappedRatings);
				}
			} catch (error) {
				console.error("Error fetching risk ratings:", error);
				dispatch(showError("Failed to fetch risk ratings"));
			} finally {
				setLoading(false);
			}
		};

		fetchRiskRatings();
	}, [dispatch]);

	const handleCreateData = () => {
		if (!input.defectRiskRating || !input.code) {
			dispatch(showError("Please fill in all required fields"));
			return;
		}

		// Use the existing structure from instanceContent
		const updatedMappings = {
			...instanceContent,
			EnumValue: {
				...instanceContent?.EnumValue,
				DefectRiskRating: {
					...instanceContent?.EnumValue?.DefectRiskRating,
					[input.defectRiskRating.id]: input.code,
				},
			},
		};

		handleClose(updatedMappings);
		setInput({
			defectRiskRating: null,
			code: "",
		});
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			{isUpdating ? <LinearProgress /> : null}
			<ADD.ActionContainer>
				<DialogTitle id="alert-dialog-title">
					{
						<ADD.HeaderText>
							Add {customCaptions?.riskRatingPlural ?? "Risk Ratings"} Mapping
						</ADD.HeaderText>
					}
				</DialogTitle>
				<ADD.ButtonContainer>
					<ADD.CancelButton
						onClick={handleClose}
						variant="contained"
						sx={{
							"&.MuiButton-root:hover": {
								backgroundColor: ColourConstants.deleteDialogHover,
								color: "#ffffff",
							},
						}}
					>
						Cancel
					</ADD.CancelButton>
					<ADD.ConfirmButton
						onClick={handleCreateData}
						variant="contained"
						className={classes.createButton}
						disabled={isUpdating}
					>
						Add Mapping
					</ADD.ConfirmButton>
				</ADD.ButtonContainer>
			</ADD.ActionContainer>
			<DialogContent className={classes.dialogContent}>
				<Box sx={{ display: "flex", gap: 2 }}>
					<div style={{ flex: 1 }}>
						<ADD.InputLabel>
							{customCaptions?.riskRating ?? "Risk Rating"}
						</ADD.InputLabel>
						<Dropdown
							options={riskRatingOptions}
							selectedValue={input.defectRiskRating}
							onChange={(value) =>
								setInput((prev) => ({ ...prev, defectRiskRating: value }))
							}
							width="100%"
						/>
					</div>
					<div style={{ flex: 1 }}>
						<ADD.InputLabel>SAP Code</ADD.InputLabel>
						<TextField
							variant="outlined"
							fullWidth
							value={input.code}
							onChange={(e) =>
								setInput((prev) => ({ ...prev, code: e.target.value }))
							}
						/>
					</div>
				</Box>
			</DialogContent>
		</Dialog>
	);
};

export default AddRiskRatingMappingsDialog;
