import { useState } from "react";
import { Accordion, AccordionDetails, AccordionActions } from "@mui/material";
import Typography from "@mui/material/Typography";
import ArrowIcon from "assets/icons/arrowIcon.svg";
import { makeStyles } from "tss-react/mui";
import ColourConstants from "helpers/colourConstants";
import AccordionSummary from "@mui/material/AccordionSummary";
import CurveButton from "components/Elements/CurveButton";
import SafteryCritical from "assets/icons/safety-critical.svg";
import { getLocalStorageData } from "helpers/utils";
import IOSSwitch from "components/Elements/IOSSwitch";
import { importLogos } from "helpers/utils";
import { useSelector } from "react-redux";

// Import logos dynamically
const logos = importLogos(require.context('assets/icons/integrations', false, /\.(png|jpg|svg)$/));

const useStyles = makeStyles()(() => ({
	logoAccordion: {
		borderColor: ColourConstants.commonBorder,
		borderStyle: "solid",
		borderWidth: 1,
		width: "100%",
	},
	expandIcon: {
		transform: "scale(0.8)",
	},
	integrationImage: {
		backgroundColor: "grey",
		height: "full",
	},
	sectionHeadingIntegration: {
		fontFamily: "Roboto Condensed",
		fontWeight: "normal",
		fontSize: "15px",
		whiteSpace: "nowrap",
	},
	sectionHeading: {
		fontFamily: "Roboto Condensed",
		fontWeight: "bold",
		fontSize: "17px",
	},
	summary: {
		alignItems: "center",
		justifyContent: "space-between",
		marginRight: "10px !important",
	},
	critical: {
		color: ColourConstants.red,
		margin: "0 !important",
	},
	criticalPara: {
		fontWeight: "600 !important",
		marginLeft: "5px",
	},
}));

function AccordionBoxForIntegration({
	title,
	accordianDetailsCss,
	buttonName,
	buttonAction,
	isActionsPresent = false,
	noExpand,
	children,
	defaultExpanded = false,
	accordionClass = "",
	style = { marginTop: "10px" },
	showSafetyCritical = false,
	lastSync,
	isActive,
	nextSync,
	type,
	isUpdating,
	onToggleActive,
	definitions
}) {
	const { classes } = useStyles();
	const { customCaptions } = getLocalStorageData("me");
	const [isExpanded, setIsExpanded] = useState(defaultExpanded);
	const [isMouseOverSwitch, setIsMouseOverSwitch] = useState(false);
	
	const definition = definitions?.find(def => def.integrationType === type);
	const logoUrl = definition?.logoUrl;

	const onIntegrationStatusUpdate = (e) => {
		e.stopPropagation();
		onToggleActive?.();
	};

	const formatSyncDate = (dateString) => {
		console.log(dateString);
		if (!dateString) return 'Never';
		
		try {
			const date = new Date(dateString + "Z"); //HACK since we're storing things in datetime in DB :( 
			if (isNaN(date.getTime())) return 'Invalid Date';

			return date.toLocaleString(undefined, {
				year: "numeric",
				month: "numeric",
				day: "numeric",
				hour: "numeric",
				minute: "numeric",
				hour12: true
			});
		} catch (error) {
			console.error('Error formatting date:', error);
			return 'Invalid Date';
		}
	};

	const lastSyncFormatted = formatSyncDate(lastSync);
	const nextSyncFormatted = formatSyncDate(nextSync);

	return (
		<Accordion
			className={`${classes.logoAccordion} ${accordionClass}`}
			expanded={isExpanded}
			onChange={() => {
				if (isMouseOverSwitch) {
					setIsExpanded((prev) => prev);
				} else {
					setIsExpanded(!isExpanded);
				}
			}}
			style={style}
		>
			<AccordionSummary
				expandIcon={
					noExpand ? null : (
						<img
							alt="Expand icon"
							src={ArrowIcon}
							className={classes.expandIcon}
						/>
					)
				}
				aria-controls="panel1a-content"
				id="panel1a-header"
				classes={{ content: classes.summary }}
			>
				<div
					classes={classes.sapIntegrationTitle}
					style={{
						display: "flex",
						justifyContent: "space-between",
						width: "100%",
						flexWrap: "wrap",
						alignItems: "center",
					}}
				>
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							flexWrap: "nowrap",
							marginRight: "50px",
							gap: "10px",
							alignItems: "center",
						}}
					>
						<span
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								backgroundColor: "#e1e5ec",
								height: "45px",
								width: "45px",
							}}
						>
							<img
								alt="Integration Logo"
								src={logos[logoUrl]}
								style={{ width: "45px", height: "45px" }}
								height={70}
								width={70}
								className={classes.printIcon}
							/>
						</span>
						<Typography className={classes.sectionHeading}>
							{type} - {title}
						</Typography>
					</div>
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							flexWrap: "wrap",
							gap: "10px",
							alignItems: "center",
						}}
					>
						<Typography className={classes.sectionHeadingIntegration}>
							<b>Last Sync:</b> {lastSyncFormatted}
						</Typography>
						<Typography className={classes.sectionHeadingIntegration}>
							<b>Next Sync:</b> {nextSyncFormatted}
						</Typography>
						<div
							onMouseEnter={() => setIsMouseOverSwitch(true)}
							onMouseLeave={() => setIsMouseOverSwitch(false)}
						>
							<IOSSwitch
								color={true}
								onChange={onIntegrationStatusUpdate}
								currentStatus={isActive}
								disabled={isUpdating}
							/>
						</div>
					</div>
				</div>
				{showSafetyCritical && (
					<p className={classes.critical}>
						<img src={SafteryCritical} alt="" />
						<span className={classes.criticalPara}>
							{customCaptions?.safetyCritical}
						</span>
					</p>
				)}
			</AccordionSummary>
			<AccordionDetails className={accordianDetailsCss}>
				{children}
			</AccordionDetails>
			{isActionsPresent && (
				<AccordionActions className={classes.actionButton}>
					<CurveButton onClick={buttonAction}>{buttonName}</CurveButton>
				</AccordionActions>
			)}
		</Accordion>
	);
}

export default AccordionBoxForIntegration;
