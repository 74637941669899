import { Grid, TextField, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { useEffect, useState } from "react";
import ColourConstants from "helpers/colourConstants";
import Dropdown from "components/Elements/Dropdown";
import { getDefectStatuses } from "services/clients/sites/siteApplications/defectStatuses";

const useStyles = makeStyles()((theme) => ({
	textInputContainer: {
		marginBottom: 17,
		width: "100%",
	},
	labelText: {
		fontFamily: "Roboto Condensed",
		fontWeight: "bold",
		fontSize: "14px",
	},
	inputText: {
		fontSize: 14,
		color: "#000000de",
	},
}));

const SAPSettings = ({
	settings,
	isEditing,
	onChange,
	customCaptions,
}) => {
	const { classes } = useStyles();
	const [defectStatuses, setDefectStatuses] = useState([]);
	const [notificationStatuses, setNotificationStatuses] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const fetchStatuses = async () => {
			try {
				const defectRes = await getDefectStatuses();
				if (defectRes.status) {
					const mappedStatuses = defectRes.data.map(status => ({
						label: status.name,
						value: status.id
					}));
					setDefectStatuses(mappedStatuses);
					setNotificationStatuses(mappedStatuses); // Use same statuses for both dropdowns
				}
			} catch (error) {
				console.error("Error fetching statuses:", error);
			} finally {
				setLoading(false);
			}
		};

		fetchStatuses();
	}, []);

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				gap: 12,
				borderTop: `1.5px solid ${ColourConstants.integrationInstanceBorder}`,
				padding: "10px 0",
			}}
		>
			<span>{customCaptions?.defectPlural ?? "Defects"} Settings</span>
			<Grid container className="desktopViewGrid" spacing={2}>
				<Grid item xs={6}>
					<div className={classes.textInputContainer}>
						<Typography gutterBottom className={classes.labelText}>
							Sync from days ago
						</Typography>
						<TextField
							sx={{
								"& .MuiInputBase-input.Mui-disabled": {
									WebkitTextFillColor: "#666666",
									backgroundColor: "#f5f5f5",
								},
								"& .MuiOutlinedInput-root.Mui-disabled": {
									"& > fieldset": {
										borderColor: "#e0e0e0",
									},
								},
							}}
							type="number"
							variant="outlined"
							fullWidth
							disabled={!isEditing}
							value={settings?.DaysAgo ?? ""}
							onChange={(e) =>
								onChange({
									...settings,
									DaysAgo:
										e.target.value === "" ? "" : parseInt(e.target.value, 10),
								})
							}
							InputProps={{
								classes: {
									input: classes.inputText,
								},
							}}
						/>
					</div>
				</Grid>
				<Grid item xs={6}>
					<div className={classes.textInputContainer}>
						<Typography gutterBottom className={classes.labelText}>
							Sync delays (hours)
						</Typography>
						<TextField
							sx={{
								"& .MuiInputBase-input.Mui-disabled": {
									WebkitTextFillColor: "#666666",
									backgroundColor: "#f5f5f5",
								},
								"& .MuiOutlinedInput-root.Mui-disabled": {
									"& > fieldset": {
										borderColor: "#e0e0e0",
									},
								},
							}}
							variant="outlined"
							type="number"
							fullWidth
							disabled={!isEditing}
							value={settings?.SyncDelayHours ?? ""}
							inputProps={{
								min: 0,
								max: 24,
							}}
							onChange={(e) =>
								onChange({
									...settings,
									SyncDelayHours:
										e.target.value === "" ? "" : parseInt(e.target.value, 10),
								})
							}
							InputProps={{
								classes: {
									input: classes.inputText,
								},
							}}
						/>
					</div>
				</Grid>
				<Grid item xs={6}>
					<div className={classes.textInputContainer}>
						<Typography gutterBottom className={classes.labelText}>
							Outstanding Status
						</Typography>
						<Dropdown
							options={defectStatuses}
							selectedValue={defectStatuses.find(
								(status) => status.value === settings?.DefectStatusID
							)}
							onChange={(value) =>
								onChange({
									...settings,
									DefectStatusID: value.value,
								})
							}
							disabled={!isEditing}
							width="100%"
							classNameForDropDown="integrations"
						/>
					</div>
				</Grid>
				<Grid item xs={6}>
					<div className={classes.textInputContainer}>
						<Typography gutterBottom className={classes.labelText}>
							Notification Raised Status
						</Typography>
						<Dropdown
							options={notificationStatuses}
							selectedValue={notificationStatuses.find(
								(status) => status.value === settings?.NotificationRaisedId
							)}
							onChange={(value) =>
								onChange({
									...settings,
									NotificationRaisedId: value.value,
								})
							}
							disabled={!isEditing}
							width="100%"
							classNameForDropDown="integrations"
						/>
					</div>
				</Grid>
			</Grid>
		</div>
	);
};

export default SAPSettings;
