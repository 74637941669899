import { makeStyles } from "tss-react/mui";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import ColourConstants from "helpers/colourConstants";

const disabledTextFieldStyle = {
	"& .MuiInputBase-input.Mui-disabled": {
		WebkitTextFillColor: "#666666",
		backgroundColor: "#f5f5f5",
	},
	"& .MuiOutlinedInput-root.Mui-disabled": {
		"& > fieldset": {
			borderColor: "#e0e0e0",
		},
	},
};

const useStyles = makeStyles()((theme) => ({
	detailsContainer: {
		marginTop: 15,
		display: "flex",
		justifyContent: "center",
	},
	expandIcon: {
		transform: "scale(0.8)",
	},
	detailsAccordion: {
		borderColor: ColourConstants.commonBorder,
		borderStyle: "solid",
		borderWidth: 1,
		width: "99.5%",
	},
	textInputContainer: {
		marginBottom: 17,
		width: "100%",
	},
	sectionHeading: {
		fontFamily: "Roboto Condensed",
		fontWeight: "bold",
		fontSize: "17px",
	},
	labelText: {
		fontFamily: "Roboto Condensed",
		fontWeight: "bold",
		fontSize: "14px",
	},
	inputText: {
		fontSize: 14,
		//cursor: "none",
		color: "#000000de",
	},
	checkboxText: {
		fontSize: 14,
	},

	assetParentContainer: {
		display: "flex",
		flexWrap: "wrap",
		width: "100%",
	},

	dividerStyle: {
		width: "100%",
		backgroundColor: ColourConstants.divider,
	},
	imageAssetContainer: {
		width: 141,
		height: 61,
		marginTop: 10,
		marginBottom: 10,
		display: "flex",
		alignItems: "center",
	},
	linkContainer: {
		display: "flex",
		alignItems: "center",
		paddingLeft: 10,
		gap: "30px",
	},
	assetImage: {
		minWidth: "100%",
		maxWidth: "100%",
		minHeight: "100%",
		maxHeight: "100%",
		objectFit: "contain",
		display: "flex",
		marginRight: 20,
		borderColor: ColourConstants.commonBorder,
		borderWidth: 1,
		borderStyle: "solid",
	},
	tickInput: {
		marginTop: "40px",
	},
	serviceTick: {
		marginTop: "5px",
	},
	tickbox_label: {
		marginLeft: "0 !important",
	},
}));

const Authentication = ({ details, isEditing, onChange, definition }) => {
	const { classes } = useStyles();
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				gap: 12,
				borderTop: `1.5px solid ${ColourConstants.integrationInstanceBorder}`,
				padding: "10px 0",
			}}
		>
			<span>Authentication</span>
			<Grid container className="desktopViewGrid" spacing={2}>
				<Grid item xs={4}>
					<div className={classes.textInputContainer}>
						<Typography gutterBottom className={classes.labelText}>
							{definition?.authEndpointLabel || "Endpoint URL"}
						</Typography>

						<TextField
							sx={disabledTextFieldStyle}
							variant="outlined"
							fullWidth
							disabled={!isEditing}
							value={details?.url ?? ""}
							onChange={(e) => onChange({
								...details,
								url: e.target.value
							})}
							InputProps={{
								classes: {
									input: classes.inputText,
								},
							}}
						/>
					</div>
				</Grid>
				<Grid item xs={4}>
					<div className={classes.textInputContainer}>
						<Typography gutterBottom className={classes.labelText}>
							{definition?.authIdLabel || "Username"}
						</Typography>

						<TextField
							sx={disabledTextFieldStyle}
							variant="outlined"
							fullWidth
							disabled={!isEditing}
							value={details?.username ?? ""}
							onChange={(e) => onChange({
								...details,
								username: e.target.value
							})}
							InputProps={{
								classes: {
									input: classes.inputText,
								},
							}}
						/>
					</div>
				</Grid>
				<Grid item xs={4}>
					<div className={classes.textInputContainer}>
						<Typography gutterBottom className={classes.labelText}>
							{definition?.authSecretLabel || "Password"}
						</Typography>

						<TextField
							sx={disabledTextFieldStyle}
							type="password"
							variant="outlined"
							fullWidth
							disabled={!isEditing}
							value={details?.password ?? ""}
							autoComplete="new-password"
							onChange={(e) => onChange({
								...details,
								password: e.target.value
							})}
							InputProps={{
								classes: {
									input: classes.inputText,
								},
							}}
						/>
					</div>
				</Grid>
			</Grid>
		</div>
	);
};

export default Authentication;
