import { makeStyles } from "tss-react/mui";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import ColourConstants from "helpers/colourConstants";
import { ReactComponent as DeleteIcon } from "assets/icons/deleteIcon.svg";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import AddRiskRatingMappingsDialog from "../../AddRiskRatingMappingsDialog";
import { useState, useEffect } from "react";
import { getDefectRiskRatings } from "services/clients/sites/siteApplications/defectRiskRatings";
import { useDispatch } from "react-redux";

const useStyles = makeStyles()((theme) => ({
	detailsContainer: {
		marginTop: 15,
		display: "flex",
		justifyContent: "center",
	},
	expandIcon: {
		transform: "scale(0.8)",
	},
	detailsAccordion: {
		borderColor: ColourConstants.commonBorder,
		borderStyle: "solid",
		borderWidth: 1,
		width: "99.5%",
	},
	textInputContainer: {
		marginBottom: 17,
		width: "100%",
	},
	sectionHeading: {
		fontFamily: "Roboto Condensed",
		fontWeight: "bold",
		fontSize: "17px",
	},
	labelText: {
		fontFamily: "Roboto Condensed",
		fontWeight: "bold",
		fontSize: "14px",
	},
	inputText: {
		fontSize: 14,
		//cursor: "none",
		color: "#000000de",
	},
	checkboxText: {
		fontSize: 14,
	},

	assetParentContainer: {
		display: "flex",
		flexWrap: "wrap",
		width: "100%",
	},

	dividerStyle: {
		width: "100%",
		backgroundColor: ColourConstants.divider,
	},
	imageAssetContainer: {
		width: 141,
		height: 61,
		marginTop: 10,
		marginBottom: 10,
		display: "flex",
		alignItems: "center",
	},
	linkContainer: {
		display: "flex",
		alignItems: "center",
		paddingLeft: 10,
		gap: "30px",
	},
	assetImage: {
		minWidth: "100%",
		maxWidth: "100%",
		minHeight: "100%",
		maxHeight: "100%",
		objectFit: "contain",
		display: "flex",
		marginRight: 20,
		borderColor: ColourConstants.commonBorder,
		borderWidth: 1,
		borderStyle: "solid",
	},
	tickInput: {
		marginTop: "40px",
	},
	serviceTick: {
		marginTop: "5px",
	},
	tickbox_label: {
		marginLeft: "0 !important",
	},
	deleteIcon: {
		transform: "scale(0.7)",
		color: ColourConstants.deleteButton,
		"&:hover": {
			cursor: "pointer",
		},
	},
	deleteIconContainer: {
		width: "50px",
		border: "none",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
}));

const FieldMappings = ({
	details,
	onMappingUpdate,
	isEditing,
	customCaptions,
}) => {
	const { classes } = useStyles();
	const dispatch = useDispatch();
	const [openEditSchedule, setOpenEditSchedule] = useState(false);
	const [riskRatingLabels, setRiskRatingLabels] = useState({});

	useEffect(() => {
		const fetchRiskRatings = async () => {
			try {
				const response = await getDefectRiskRatings();
				if (response.status) {
					const labelMap = response.data.reduce(
						(acc, rating) => ({
							...acc,
							[rating.id]: rating.name,
						}),
						{}
					);
					setRiskRatingLabels(labelMap);
				}
			} catch (error) {
				console.error("Error fetching risk ratings:", error);
			}
		};

		fetchRiskRatings();
	}, []);

	const handleSapCodeChange = (riskRatingId, newCode) => {
		const updatedMappings = {
			...details,
			EnumValue: {
				...details.EnumValue,
				DefectRiskRating: {
					...details.EnumValue.DefectRiskRating,
					[riskRatingId]: newCode,
				},
			},
		};
		onMappingUpdate(updatedMappings);
	};

	const handleDeleteMapping = (riskRatingId) => {
		const updatedDefectRiskRating = { ...details?.EnumValue?.DefectRiskRating };
		delete updatedDefectRiskRating[riskRatingId];

		const updatedMappings = {
			...details,
			EnumValue: {
				...details.EnumValue,
				DefectRiskRating: updatedDefectRiskRating,
			},
		};

		onMappingUpdate(updatedMappings);
	};

	return (
		<>
			<AddRiskRatingMappingsDialog
				open={openEditSchedule}
				handleClose={(updatedMappings = null) => {
					if (updatedMappings !== null) {
						onMappingUpdate(updatedMappings);
					}
					setOpenEditSchedule(false);
				}}
				instanceContent={details}
				customCaptions={customCaptions}
			/>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					gap: 12,
					borderTop: `1.5px solid ${ColourConstants.integrationInstanceBorder}`,
					padding: "10px 0",
				}}
			>
				<span>Field Mappings</span>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						gap: 4,
					}}
				>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							gap: 2,
							padding: "10px 0 0 0",
						}}
					>
						<span style={{ fontSize: 14 }}>
							{customCaptions?.riskRatingPlural ?? "Risk Ratings"}
						</span>
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
								gap: 4,
							}}
						>
							<Typography
								variant="body1"
								color="text.secondary"
								sx={{ font: "bold", fontSize: 14 }}
							>
								{customCaptions?.defectPlural ?? "Defects"} without a{" "}
								{customCaptions?.riskRatingPlural ?? "risk ratings"} mapped will
								not be sent to SAP.
							</Typography>
							<Button
								sx={{
									textTransform: "capitalize",
									textDecoration: "underline",
									textUnderlineOffset: 2,
									opacity: !isEditing ? 0.5 : 1,
									"&:hover": {
										cursor: !isEditing ? "not-allowed" : "pointer",
									},
								}}
								startIcon={<AddIcon />}
								onClick={() =>
									isEditing && setOpenEditSchedule((prev) => !prev)
								}
								color="success"
								disabled={!isEditing}
							>
								Add Mapping
							</Button>
						</div>
					</div>
					<div style={{ display: "flex", flexDirection: "column", gap: 4 }}>
						{/* Headers */}
						<Grid container spacing={2} sx={{ mb: 2 }}>
							<Grid item xs={6}>
								<Typography className={classes.labelText}>
									{customCaptions?.riskRatingPlural ?? "Risk Ratings"}
								</Typography>
							</Grid>
							<Grid item xs={5.5}>
								<Typography className={classes.labelText}>SAP Code</Typography>
							</Grid>
						</Grid>

						{/* Sorted Mappings */}
						{Object.entries(details?.EnumValue?.DefectRiskRating || {})
							.sort((a, b) => {
								const labelA = riskRatingLabels[a[0]] || a[0];
								const labelB = riskRatingLabels[b[0]] || b[0];
								return labelA.localeCompare(labelB);
							})
							.map(([riskRatingId, sapCode]) => (
								<Grid
									container
									className="desktopViewGrid"
									spacing={2}
									key={riskRatingId}
									sx={{ mb: 1.5 }}
								>
									<Grid item xs={6} sx={{ pt: "8px!important" }}>
										<TextField
											sx={{
												"& .MuiInputBase-input.Mui-disabled": {
													WebkitTextFillColor: "#666666",
													backgroundColor: "#f5f5f5",
												},
												"& .MuiOutlinedInput-root.Mui-disabled": {
													"& > fieldset": {
														borderColor: "#e0e0e0",
													},
												},
											}}
											variant="outlined"
											fullWidth
											disabled
											value={riskRatingLabels[riskRatingId] || riskRatingId}
											InputProps={{
												classes: {
													input: classes.inputText,
												},
											}}
										/>
									</Grid>
									<Grid item xs={5.5} sx={{ pt: "8px!important" }}>
										<TextField
											sx={{
												"& .MuiInputBase-input.Mui-disabled": {
													WebkitTextFillColor: "#666666",
													backgroundColor: "#f5f5f5",
												},
												"& .MuiOutlinedInput-root.Mui-disabled": {
													"& > fieldset": {
														borderColor: "#e0e0e0",
													},
												},
											}}
											variant="outlined"
											fullWidth
											disabled={!isEditing}
											value={sapCode}
											onChange={(e) =>
												handleSapCodeChange(riskRatingId, e.target.value)
											}
											InputProps={{
												classes: {
													input: classes.inputText,
												},
											}}
										/>
									</Grid>
									<div className={classes.deleteIconContainer}>
										<DeleteIcon
											className={classes.deleteIcon}
											onClick={() =>
												isEditing && handleDeleteMapping(riskRatingId)
											}
											sx={{
												opacity: !isEditing ? 0.5 : 0.7,
												cursor: !isEditing ? "not-allowed" : "pointer",
											}}
										/>
									</div>
								</Grid>
							))}
					</div>
				</div>
			</div>
		</>
	);
};

export default FieldMappings;
