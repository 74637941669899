import { Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

export default function EditScheduleLink({ onClick, isEditing, ...props }) {
	return (
		<Button
			variant="text"
			color="primary"
			startIcon={<EditIcon sx={{ marginRight: -0.5, fontSize: 15 }} />}
			onClick={onClick}
			sx={{
				padding: "4px 8px",
				minWidth: "auto",
				textTransform: "none",
				opacity: !isEditing ? 0.5 : 1,
				"&:hover": {
					backgroundColor: "transparent",
					textDecoration: isEditing ? "underline" : "none",
					cursor: !isEditing ? 'not-allowed' : 'pointer'
				},
			}}
			disabled={!isEditing}
			onClick={(e) => isEditing && onClick(e)}
			{...props}
		>
			Edit Schedule
		</Button>
	);
}
