import { Grid, TextField, Typography, Checkbox, FormControlLabel } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import ColourConstants from "helpers/colourConstants";

const useStyles = makeStyles()((theme) => ({
	textInputContainer: {
		marginBottom: 17,
		width: "100%",
	},
	labelText: {
		fontFamily: "Roboto Condensed",
		fontWeight: "bold",
		fontSize: "14px",
	},
	inputText: {
		fontSize: 14,
		color: "#000000de",
	},
	checkboxGroup: {
		display: "flex",
		flexDirection: "column",
		gap: 8,
	},
}));

const entityTypeOptions = [
	{ label: "Service", value: "Service" },
	{ label: "Task List", value: "TaskList" },
];

const S3Settings = ({ settings, isEditing, onChange, customCaptions }) => {
	const { classes } = useStyles();

	const handleEntityTypeChange = (value) => {
		const currentTypes = settings?.EntityTypes || [];
		const updatedTypes = currentTypes.includes(value)
			? currentTypes.filter((type) => type !== value)
			: [...currentTypes, value];

		onChange({
			...settings,
			EntityTypes: updatedTypes,
		});
	};

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				gap: 12,
				borderTop: `1.5px solid ${ColourConstants.integrationInstanceBorder}`,
				padding: "10px 0",
			}}
		>
			<span>S3 Export Settings</span>
			<Grid container spacing={2}>
				<Grid item xs={6}>
					<div className={classes.textInputContainer}>
						<Typography gutterBottom className={classes.labelText}>
							Base Folder
						</Typography>
						<TextField
							sx={{
								"& .MuiInputBase-input.Mui-disabled": {
									WebkitTextFillColor: "#666666",
									backgroundColor: "#f5f5f5",
								},
								"& .MuiOutlinedInput-root.Mui-disabled": {
									"& > fieldset": {
										borderColor: "#e0e0e0",
									},
								},
							}}
							variant="outlined"
							fullWidth
							disabled={!isEditing}
							value={settings?.BaseFolder ?? ""}
							onChange={(e) =>
								onChange({
									...settings,
									BaseFolder: e.target.value,
								})
							}
							InputProps={{
								classes: {
									input: classes.inputText,
								},
							}}
						/>
					</div>
				</Grid>
				<Grid item xs={6}>
					<div className={classes.textInputContainer}>
						<Typography gutterBottom className={classes.labelText}>
							Days to Sync
						</Typography>
						<TextField
							sx={{
								"& .MuiInputBase-input.Mui-disabled": {
									WebkitTextFillColor: "#666666",
									backgroundColor: "#f5f5f5",
								},
								"& .MuiOutlinedInput-root.Mui-disabled": {
									"& > fieldset": {
										borderColor: "#e0e0e0",
									},
								},
							}}
							type="number"
							variant="outlined"
							fullWidth
							disabled={!isEditing}
							value={settings?.DaysToSync ?? ""}
							onChange={(e) =>
								onChange({
									...settings,
									DaysToSync:
										e.target.value === "" ? "" : parseInt(e.target.value, 10),
								})
							}
							InputProps={{
								classes: {
									input: classes.inputText,
								},
							}}
						/>
					</div>
				</Grid>
				<Grid item xs={6}>
					<div className={classes.textInputContainer}>
						<Typography gutterBottom className={classes.labelText}>
							Entity Types
						</Typography>
						<div className={classes.checkboxGroup}>
							{entityTypeOptions.map((option) => (
								<FormControlLabel
									key={option.value}
									control={
										<Checkbox
											checked={settings?.EntityTypes?.includes(option.value) ?? false}
											onChange={() => handleEntityTypeChange(option.value)}
											disabled={!isEditing}
										/>
									}
									label={option.label}
								/>
							))}
						</div>
					</div>
				</Grid>
				<Grid item xs={6}>
					<div className={classes.textInputContainer}>
						<Typography gutterBottom className={classes.labelText}>
							Additional Settings
						</Typography>
						<FormControlLabel
							control={
								<Checkbox
									checked={settings?.IncludeCompletedRecords ?? false}
									onChange={(e) =>
										onChange({
											...settings,
											IncludeCompletedRecords: e.target.checked,
										})
									}
									disabled={!isEditing}
								/>
							}
							label="Include Completed Records"
						/>
					</div>
				</Grid>
			</Grid>
		</div>
	);
};

export default S3Settings; 