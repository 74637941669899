import { useState, useEffect, useCallback } from "react";
import { CircularProgress } from "@mui/material";
import { showError } from "../../../../../../redux/common/actions";
import ContentStyle from "styles/application/ContentStyle";
import TabTitle from "components/Elements/TabTitle";
import AccordionBoxForIntegration from "components/Layouts/AccordionBoxForIntegration";
import EmptyIntegrationContainer from "../EmptyIntegrationContainer";
import AddNewIntegrationModal from "../AddNewIntegrationModal";
import IntegrationInstanceCardLayout from "../IntegrationInstancesCardLayout";
import { 
	getIntegrationInstances, 
	updateIntegrationInstance, 
	deleteIntegrationInstance, 
	getIntegrationDefinitions
} from "services/integrations";

const AC = ContentStyle();

const IntegrationContent = ({ id, state, dispatch }) => {
	const [integrations, setIntegrations] = useState([]);
	const [definitions, setDefinitions] = useState([]);
	const [hasIntegrations, setHasIntegrations] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isUpdating, setIsUpdating] = useState(false);
	const [showAddModal, setShowAddModal] = useState(false);

	const fetchIntegrations = useCallback(async () => {
		setIsUpdating(true);
		try {
			const response = await getIntegrationInstances();
			if (response?.status) {
				const formattedIntegrations = response.data?.map((item) => ({
					...item,
					fieldMappings: JSON.parse(item?.fieldMappings || '{}'),
					settings: JSON.parse(item?.settings || '{}')
				}));
				setIntegrations(formattedIntegrations);
				setHasIntegrations(response.data?.length > 0);
			}
		} catch (error) {
			console.error("Error fetching integrations:", error);
			dispatch(showError("Failed to fetch integrations"));
		} finally {
			setIsUpdating(false);
			setIsLoading(false);
		}
	}, [dispatch]);


	const fetchDefinitions = useCallback(async () => {
		try {
			const response = await getIntegrationDefinitions();
			if (response.status) {
				setDefinitions(response.data);
			}
		} catch (error) {
			console.error("Error fetching integration definitions:", error);
			dispatch(showError("Failed to fetch integration types"));
		} finally {
			setIsLoading(false);
		}
	}, [dispatch]);

	const handleDelete = async (id) => {
		setIsUpdating(true);
		try {
			const response = await deleteIntegrationInstance(id);
			if (response.status) {
				await fetchIntegrations();
			} else {
				dispatch(showError("Failed to delete integration"));
			}
		} catch (error) {
			dispatch(showError("Failed to delete integration"));
		} finally {
			setIsUpdating(false);
		}
	};

	const handleToggleActive = async (instance) => {
		setIsUpdating(true);
		try {
			const response = await updateIntegrationInstance(instance.id, [{
				op: "replace",
				path: "/isActive",
				value: !instance.isActive
			}]);
			
			if (response.status) {
				await fetchIntegrations();
			} else {
				dispatch(showError("Failed to update integration status"));
			}
		} catch (error) {
			dispatch(showError("Failed to update integration status"));
		} finally {
			setIsUpdating(false);
		}
	};

	useEffect(() => {
		setIsLoading(true);
		fetchIntegrations();
		fetchDefinitions();
	}, [fetchIntegrations, fetchDefinitions]);

	useEffect(() => {
		if (state.showAdd) {
			setShowAddModal(true);
		}
	}, [state.showAdd]);

	const handleModalClose = (wasAdded = false) => {
		setShowAddModal(false);
		if (state.onModalClose) {
			state.onModalClose(wasAdded);
		}
		if (wasAdded) {
			fetchIntegrations();
		}
	};

	return (
		<div>
			<TabTitle title={`${state?.details?.data?.application?.name} Integrations`} />

			{isLoading ? (
				<AC.SpinnerContainer>
					<CircularProgress />
				</AC.SpinnerContainer>
			) : hasIntegrations ? (
				<>
					{integrations?.map((instance) => (
						<AccordionBoxForIntegration
							key={instance.id}
							title={instance?.name}
							type={instance?.integrationType}
							lastSync={instance?.lastSyncAttempt}
							nextSync={instance?.nextSyncAttempt}
							isActive={instance?.isActive}
							onToggleActive={() => handleToggleActive(instance)}
							isUpdating={isUpdating}
							definitions={definitions}
						>
							<IntegrationInstanceCardLayout 
								details={instance}
								onSave={fetchIntegrations}
								onDelete={() => handleDelete(instance.id)}
								definitions={definitions}
							/>
						</AccordionBoxForIntegration>
					))}
				</>
			) : (
				<EmptyIntegrationContainer onClickAdd={() => setShowAddModal(true)} />
			)}

			<AddNewIntegrationModal
				open={showAddModal}
				onModalClose={handleModalClose}
				existingIntegrations={integrations}
				definitions={definitions}
			/>
		</div>
	);
};

export default IntegrationContent;
