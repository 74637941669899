import API from "helpers/api";
import { Apis } from "services/api";
import { getAPIResponse } from "helpers/getApiResponse";

export const getIntegrationInstances = async () => {
  try {
    let response = await API.get(`${Apis.IntegrationInstances}`);
    return getAPIResponse(response);
  } catch (err) {
    return getAPIResponse(err?.response);
  }
};

export const getIntegrationDefinitions = async () => {
  try {
    let response = await API.get(`${Apis.IntegrationDefinition}`);
    return getAPIResponse(response);
  } catch (err) {
    return getAPIResponse(err?.response);
  }
};

export const addIntegrationInstance = async (payload) => {
  try {
    let response = await API.post(`${Apis.IntegrationInstances}`, payload);
    return getAPIResponse(response);
  } catch (err) {
    return getAPIResponse(err?.response);
  }
};

export const updateIntegrationInstance = async (id, payload) => {
  try {
    let response = await API.patch(
      `${Apis.IntegrationInstances}/${id}`,
      payload
    );
    return getAPIResponse(response);
  } catch (err) {
    return getAPIResponse(err?.response);
  }
};

export const deleteIntegrationInstance = async (id) => {
  try {
    let response = await API.delete(`${Apis.IntegrationInstances}/${id}`);
    return getAPIResponse(response);
  } catch (err) {
    return getAPIResponse(err?.response);
  }
};
