import { Box, Typography, Card, CardContent, Tooltip } from "@mui/material";
import PhotoIcon from "@mui/icons-material/Photo";
import ColourConstants from "helpers/colourConstants";
import { makeStyles } from "tss-react/mui";
import ActionButtonStyle from "styles/application/ActionButtonStyle";
import { importLogos } from "helpers/utils";

const AT = ActionButtonStyle();

// Import logos dynamically
const logos = importLogos(require.context('assets/icons/integrations', false, /\.(png|jpg|svg)$/));

const useStyles = makeStyles()((theme) => ({
	dialogContent: {
		display: "flex",
		flexDirection: "column",
	},
	createButton: {
		width: "auto",
	},
	integrationCard: {
		display: "flex",
		alignItems: "flex-start",
		width: "100%",
		padding: "8px 0",
	},
	addNew: {
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end",
		width: "100%",
	},
	logoContainer: {
		width: 80,
		minHeight: 80,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		flexShrink: 0,
		backgroundColor: "#e1e5ec",
		marginRight: "16px",
	},
	contentContainer: {
		flex: 1,
		paddingRight: "16px",
	}
}));

export default function IntegrationCard({
	isDisabled,
	name,
	integrationType,
	id,
	logoUrl,
	description,
	handleCreateProcess,
	isAdding,
	handleClose,
}) {
	const { classes } = useStyles();
	
	console.log(`${name} - isAdding: ${isAdding}, isDisabled: ${isDisabled}`);

	return (
		<Card
			variant="outlined"
			sx={{
				display: "flex",
				flexDirection: "column",
				p: 2,
				maxWidth: "100%",
				borderRadius: 0,
				borderColor: "#e0e0e0",
				minHeight: 140,
			}}
		>
			<div className={classes.integrationCard}>
				<Box className={classes.logoContainer}>
					{logoUrl ? (
						<img
							alt="Integration Logo"
							src={logos[logoUrl]}
							style={{ width: "70px", height: "70px" }}
							height={70}
							width={70}
						/>
					) : (
						<PhotoIcon sx={{ fill: "#a2adb8", width: 30, height: 30 }} />
					)}
				</Box>

				<div className={classes.contentContainer}>
					<Typography
						variant="subtitle1"
						component="h3"
						sx={{
							fontWeight: 600,
							color: "#2c3e50",
							mb: 0.5,
							fontSize: 18,
						}}
					>
						{name ?? "Integration Name"}
					</Typography>
					<Typography
						variant="body2"
						sx={{
							color: "#64748b",
							lineHeight: 1.5,
						}}
					>
						{description ?? "Integration Description"}
					</Typography>
				</div>
			</div>

			<div className={classes.addNew}>
				<Tooltip
					title={isDisabled ? "This integration is unique and already exists for this Site Application" : ""}
					arrow
				>
					<span>
						<AT.GeneralButton
							onClick={handleCreateProcess}
							variant="contained"
							disabled={isAdding || isDisabled}
							className={classes.createButton}
							sx={{
								"&.MuiButton-root:hover": {
									backgroundColor: ColourConstants.deleteDialogHover,
									color: "#ffffff",
								},
								width: 120,
							}}
						>
							Add New
						</AT.GeneralButton>
					</span>
				</Tooltip>
			</div>
		</Card>
	);
}
