import {
	Dialog,
	DialogContent,
	DialogTitle
  } from "@mui/material";
  import { makeStyles } from "tss-react/mui";
  import { useState, useEffect } from "react";
  import AddDialogStyle from "styles/application/AddDialogStyle";
  import ColourConstants from "helpers/colourConstants";
  import TimeScheduleEdit from "./TimeScheduleEdit";
  
  const ADD = AddDialogStyle();
  const useStyles = makeStyles()((theme) => ({
	dialogContent: {
	  display: "flex",
	  flexDirection: "column",
	},
	createButton: {
	  width: "auto",
	},
  }));
  
  const EditIntegrationScheduleDialog = ({
	open,
	handleClose,
	instanceContent,
	onSettingsUpdate
  }) => {
	const { classes } = useStyles();
  
	// Parent local state is the single source of truth
	const [localSettings, setLocalSettings] = useState({
	  SyncPeriodHours: instanceContent?.settings?.SyncPeriodHours ?? null,
	  SyncTimeOfDay: instanceContent?.settings?.SyncTimeOfDay ?? []
	});
  
	// If the parent prop may change externally (e.g. opening a new dialog with new data),
	// update local state accordingly:
	useEffect(() => {
	  setLocalSettings({
		SyncPeriodHours: instanceContent?.settings?.SyncPeriodHours ?? null,
		SyncTimeOfDay: instanceContent?.settings?.SyncTimeOfDay ?? []
	  });
	}, [instanceContent?.settings]);
  
	// The child calls `onScheduleChange(newSchedule)`, and we merge changes here
	const handleScheduleChange = (newSchedule) => {
	  setLocalSettings((prev) => ({
		...prev,
		...newSchedule
	  }));
	};
  
	// On save, pass the merged state back to parent or server
	const handleSave = () => {
	  onSettingsUpdate({
		...instanceContent?.settings,
		...localSettings
	  });
	  handleClose();
	};
  
	return (
	  <Dialog
		open={open}
		onClose={handleClose}
		aria-labelledby="alert-dialog-title"
		aria-describedby="alert-dialog-description"
	  >
		<ADD.ActionContainer>
		  <DialogTitle id="alert-dialog-title">
			{<ADD.HeaderText>Edit Sync Schedule</ADD.HeaderText>}
		  </DialogTitle>
		  <ADD.ButtonContainer>
			<ADD.CancelButton
			  onClick={handleClose}
			  variant="contained"
			  sx={{
				"&.MuiButton-root:hover": {
				  backgroundColor: ColourConstants.deleteDialogHover,
				  color: "#ffffff",
				},
			  }}
			>
			  Cancel
			</ADD.CancelButton>
			<ADD.ConfirmButton
			  onClick={handleSave}
			  variant="contained"
			  className={classes.createButton}
			  sx={{
				"&.MuiButton-root:hover": {
				  backgroundColor: ColourConstants.deleteDialogHover,
				  color: "#ffffff",
				},
			  }}
			>
			  Save Changes
			</ADD.ConfirmButton>
		  </ADD.ButtonContainer>
		</ADD.ActionContainer>
  
		<DialogContent className={classes.dialogContent}>
		  {/* We pass down `localSettings` as props,
			  plus a callback that merges any changes back into `localSettings` */}
		  <TimeScheduleEdit
			schedule={localSettings}
			onScheduleChange={handleScheduleChange}
		  />
		</DialogContent>
	  </Dialog>
	);
  };
  
  export default EditIntegrationScheduleDialog;
  