import { makeStyles } from "tss-react/mui";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Typography from "@mui/material/Typography";
import ColourConstants from "helpers/colourConstants";
import { checkIsDefectPending, formatAMPM } from "helpers/utils";
import { changeDate } from "helpers/date";
import WarningMessageWithErrorIcon from "./WarningMessageWithErrorIcon";
import ErrorMessageWithErrorIcon from "./ErrorMessageWithErrorIcon";

const media = "@media (max-width: 414px)";
const theme = createTheme();
const useStyles = makeStyles()((theme) => ({
	mainDiv: {
		marginLeft: "5px",
		paddingLeft: "2px",
		display: "flex",
		[media]: {
			marginLeft: 0,
			display: "block",
			width: "100%",
		},
	},
	childDiv: {
		marginLeft: "8px",
		paddingTop: "3px",
		paddingBottom: "4px",
		display: "flex",
		color: ColourConstants.commonText,
		[media]: {
			marginLeft: 0,
		},
	},
	boldText: {
		fontWeight: "bold",
		paddingRight: "8px",
		fontSize: "14px",
		display: "flex",
		justifyContent: "start",
		alignItems: "center",
	},
	simpleText: {
		fontSize: "14px",
		display: "flex",
		gap: "5px",
		justifyContent: "start",
		alignItems: "center",
	},
	linkName: {
		textDecoration: "underline",
		textUnderlineOffset: "3px",
		color: "#307AD6",
		"&:hover": {
			cursor: "pointer",
		},
	},
}));

const getStatusIcon = (status) => {
	switch (status) {
		case "error":
			return <ErrorMessageWithErrorIcon />;
		case "pending":
			return <WarningMessageWithErrorIcon />;
		case "success":
			return <CheckCircleIcon style={{ color: "#24BA78" }} />;
		default:
			return null;
	}
};

const DefectDetailIntegrationHistory = ({ defectDetail }) => {
	let localTime = changeDate(defectDetail?.defectDetail?.sapExportDateTime);
	let amPm = formatAMPM(
		new Date(defectDetail?.defectDetail?.sapExportDateTime + "Z")
	);
	const { classes } = useStyles();

	const isSynchronized =
		!defectDetail?.defectDetail?.sapError &&
		defectDetail?.defectDetail?.sapExportDateTime;
	const hasError = defectDetail?.defectDetail?.sapError;
	const isDefectPending = checkIsDefectPending(
		defectDetail?.defectDetail,
		defectDetail?.defectIntegrationInstance
	);
	const status = isSynchronized
		? "success"
		: hasError
		? "error"
		: isDefectPending
		? "pending"
		: "";
	const title = isSynchronized
		? "Synchronized"
		: hasError
		? "Error Syncing"
		: isDefectPending
		? "Defect pending"
		: "";

	return (
		<ThemeProvider theme={theme}>
			<div className={`${classes.mainDiv} mt-sm`}>
				<div className={classes.childDiv}>
					<Typography className={classes.boldText}>
						{getStatusIcon(status)}
					</Typography>
					<Typography className={classes.simpleText}>
						{title} {hasError && "-"}
						{hasError && (
							<div
								className={classes.linkName}
								onClick={() =>
									document
										.getElementById("integration-name-status")
										.scrollIntoView()
								}
								style={{ cursor: "pointer", textDecoration: "underline" }}
							>
								Error Details
							</div>
						)}
					</Typography>
				</div>
				{isSynchronized && defectDetail?.defectDetail?.sapExportDateTime && (
					<>
						<div className={classes.childDiv}>
							<Typography className={classes.boldText}>on</Typography>
							<Typography className={classes.simpleText}>
								{localTime}
							</Typography>
						</div>
						<div className={classes.childDiv}>
							<Typography className={classes.boldText}>at</Typography>
							<Typography className={classes.simpleText}>{amPm}</Typography>
						</div>
					</>
				)}
			</div>
		</ThemeProvider>
	);
};

export default DefectDetailIntegrationHistory;
