import { Box, Typography, Paper } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ActionButtonStyle from "styles/application/ActionButtonStyle";
const AT = ActionButtonStyle();
export default function EmptyIntegrationContainer({ onClickAdd }) {
	return (
		<>
			<Paper
				elevation={0}
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center",
					minHeight: "300px",
					bgcolor: "#c3cfd9",
					borderRadius: 2,
					p: 3,
					marginTop: "30px",
				}}
			>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						width: 50,
						height: 50,
						borderRadius: "50%",
						bgcolor: "#f0f0f2",
						mb: 2,
					}}
				>
					<AddIcon color="action" />
				</Box>
				<Typography variant="h6" component="h3" gutterBottom align="center">
					No integrations configured
				</Typography>
				<Typography
					variant="body2"
					color="text.secondary"
					align="center"
					sx={{ mb: 2 }}
				>
					Click 'Add New' to configure your first integration
				</Typography>
				<AT.GeneralButton onClick={onClickAdd}>
					Add New
				</AT.GeneralButton>
			</Paper>
		</>
	);
}
