import React, { useState } from "react";
import { Box, Button, Tooltip } from "@mui/material";
import { useDispatch } from "react-redux";
import { showError } from "../../../../../../redux/common/actions";
import Authentication from "./Authentication";
import FieldMappings from "./FieldMappings";
import SAPSettings from "../SAP/SAPSettings";
import S3Settings from "../S3/S3Settings";
import ScheduleSettings from "./ScheduleSettings";
import { updateIntegrationInstance } from "services/integrations";
import ColourConstants from "helpers/colourConstants";
import { ReactComponent as DeleteIcon } from "assets/icons/deleteIcon.svg";
import { makeStyles } from "tss-react/mui";
import EditIntegrationScheduleDialog from "../EditIntegrationScheduleDialog";

// Create a map of integration types to their corresponding components
const INTEGRATION_COMPONENTS = {
  "SAP-Hana-Cloud": {
    settings: SAPSettings,
    showFieldMappings: true,
  },
  "AmazonS3CloudSync": {
    settings: S3Settings,
    showFieldMappings: false,
  },
  // Add more integration types here as needed
};

const IntegrationInstanceCardLayout = React.forwardRef(({ 
  details,
  onSave,
  onDelete,
  definitions
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [openEditSchedule, setOpenEditSchedule] = useState(false);
  const dispatch = useDispatch();
  const [localInstance, setLocalInstance] = useState({
    settings: details.settings,
    fieldMappings: details.fieldMappings,
    url: details.url,
    username: details.username,
    password: details.password
  });
  const { customCaptions } = JSON.parse(localStorage.getItem("me")) || JSON.parse(sessionStorage.getItem("me"));

  const useStyles = makeStyles()((theme) => ({
		scheduledTime: {
			borderTop: `1.5px solid  ${ColourConstants.integrationInstanceBorder}`,
			padding: "12px 0",
		},
		deleteIcon: {
			transform: "scale(0.7)",
			color: ColourConstants.deleteButton,
			"&:hover": {
				cursor: "pointer",
			},
		},
	}));

  const definition = definitions.find(def => def.integrationType === details.integrationType);

  const handleInstanceChange = (updates) => {
    setLocalInstance(prev => ({
      ...prev,
      ...updates,
      settings: updates.settings || prev.settings,
      fieldMappings: updates.fieldMappings || prev.fieldMappings
    }));
  };

  const handleSave = async () => {
    try {
      const patches = [];
      
      // Compare and create patches for changed fields
      if (localInstance.url !== details.url) {
        patches.push({
          op: "replace",
          path: "/url",
          value: localInstance.url
        });
      }
      if (localInstance.username !== details.username) {
        patches.push({
          op: "replace",
          path: "/username",
          value: localInstance.username
        });
      }
      if (localInstance.password !== details.password) {
        patches.push({
          op: "replace",
          path: "/password",
          value: localInstance.password
        });
      }
      if (JSON.stringify(localInstance.settings) !== JSON.stringify(details.settings)) {
        patches.push({
          op: "replace",
          path: "/settings",
          value: JSON.stringify(localInstance.settings)
        });
      }
      if (JSON.stringify(localInstance.fieldMappings) !== JSON.stringify(details.fieldMappings)) {
        patches.push({
          op: "replace",
          path: "/fieldMappings",
          value: JSON.stringify(localInstance.fieldMappings)
        });
      }

      if (patches.length > 0) {
        const response = await updateIntegrationInstance(details.id, patches);
        if (response.status) {
          onSave();
          setIsEditing(false);
        } else {
          dispatch(showError("Failed to update integration settings"));
        }
      } else {
        setIsEditing(false);
      }
    } catch (error) {
      dispatch(showError("Failed to update integration settings"));
    }
  };

  const { classes } = useStyles();

  const handleEdit = () => {
    if (details.isActive) {
      dispatch(showError("Disable integration before editing settings"));
      return;
    }
    setIsEditing(true);
  };

  const onCancel = () => {
    setIsEditing(false);
    setLocalInstance({
      settings: details.settings,
      fieldMappings: details.fieldMappings,
      url: details.url,
      username: details.username,
      password: details.password
    });
  };

  const renderIntegrationSettings = () => {
    const integrationConfig = INTEGRATION_COMPONENTS[details.integrationType];
    
    if (!integrationConfig) return null;

    const SettingsComponent = integrationConfig.settings;
    
    return (
      <>
        <SettingsComponent 
          settings={localInstance.settings}
          isEditing={isEditing}
          onChange={(newSettings) => handleInstanceChange({ settings: newSettings })}
          customCaptions={customCaptions}
        />
        {integrationConfig.showFieldMappings && (
          <FieldMappings 
            details={localInstance.fieldMappings}
            isEditing={isEditing}
            onMappingUpdate={(updatedMappings) => handleInstanceChange({ fieldMappings: updatedMappings })}
            customCaptions={customCaptions}
          />
        )}
      </>
    );
  };

  return (
    <div>
      <Authentication 
        details={localInstance}
        isEditing={isEditing}
        onChange={handleInstanceChange}
        definition={definition}
      />
      <ScheduleSettings
        settings={localInstance.settings}
        isEditing={isEditing}
        onEditSchedule={() => setOpenEditSchedule(true)}                                                                                                                 
        customCaptions={customCaptions}
      />
      {renderIntegrationSettings()}
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          mt: 4,
          borderTop: `1.5px solid  ${ColourConstants.integrationInstanceBorder}`,
          padding: "12px 0",
        }}
      >
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          {!isEditing ? (
            <>
              <Tooltip title={details.isActive ? "Disable integration to edit settings" : ""}>
                <span>
                  <Button 
                    onClick={handleEdit}
                    disabled={details.isActive} 
                    sx={{
                      opacity: details.isActive ? 0.5 : 1,
                      '&:hover': {
                        cursor: details.isActive ? 'not-allowed' : 'pointer'
                      }
                    }}
                  >
                    Edit Settings
                  </Button>
                </span>
              </Tooltip>
              <DeleteIcon
                className={classes.deleteIcon} 
                onClick={onDelete}
              />
            </>
          ) : (
            <>
              <Button onClick={onCancel}>
                Cancel
              </Button>
              <Button 
                variant="contained"
                onClick={handleSave}
                disabled={details.isActive}
              >
                Save Changes
              </Button>
            </>
          )}
        </Box>
      </Box>
      <EditIntegrationScheduleDialog
        open={openEditSchedule}
        handleClose={() => setOpenEditSchedule(false)}
        instanceContent={localInstance}
        onSettingsUpdate={(newSettings) => handleInstanceChange({ settings: newSettings })}
      />
    </div>
  );
});

export default IntegrationInstanceCardLayout;
